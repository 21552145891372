import React, { Fragment } from "react"
import { colors } from "Utilities"
import styled from "styled-components"

const infoText = {
  color: "white",
  textDecoration: "none",
  textShadow: "none",
}

const buttonText = {
  color: "black",
  textDecoration: "none",
  textShadow: "none",
}

const LowerBorder = () => (
  <Fragment>
    <Border>
      <a
        href="https://via.eviivo.com/TheLawnsBTA7"
        target="_blank"
        rel="noopener noreferrer"
        style={buttonText}
      >
        <ButtonWrapper>Book Now</ButtonWrapper>
      </a>
      <CallDirectWrapper>
        <p>
          or call us direct on{" "}
          <a style={infoText} href="tel:01458211172">
            01458 211172
          </a>
        </p>
      </CallDirectWrapper>
    </Border>
  </Fragment>
)

export default LowerBorder

const Border = styled.div`
  width: 100%;
  background: #1a2e05;
  border-radius: 5px;
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`
const CallDirectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  p {
    margin: 0;
    color: ${colors.white};
    font-size: 1.5rem;
    @media (max-width: 600px) {
      font-size: 0.9rem;
    }
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.8rem;
  text-decoration: none;
  border-radius: 10px;
  border-color: ${colors.black};
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  max-width: 200px;
  background: ${colors.white};
  @media (max-width: 600px) {
    padding: 0.5rem 0.9rem;
  }
  a {
    color: ${colors.black};
    text-decoration: none;
    text-shadow: none;
  }
`
